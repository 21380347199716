import React, { useRef, useEffect, useState } from "react";
import { PageHeader, Breadcrumb, Button, Tooltip, Space, DatePicker, Col, Input, Row } from "antd";
import config from "./config";
import { useNavigate, Link } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import pluralize from "pluralize";
import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import _ from "lodash";
import qs from "qs";
import moment from "moment";
import { useSelector } from "react-redux";
import { MAX_PAGINATION_LIMIT } from "../../../../../constants";
import { axios } from "../../../../../App";
import { ReportDataTable } from "../../../components";
import { Bar, Column, Pie } from "@ant-design/plots";

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

const Page = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [searched, setSearched] = useState(false);
  const { user } = useSelector((state) => state?.auth);
  const [chartData, setchartData] = useState([]);
  const [secondChartData, setSecondChartData] = useState([])


  const secondChart = (response) => {
    let completedTasks = 0;
    let incompleteTasks = 0;
  
    response?.data?.data?.forEach(item => {
      const tasks = item?.attributes?.tasks || [];
      tasks.forEach(task => {
        if (task.completed === "Yes") {
          completedTasks++;
        } else {
          incompleteTasks++;
        }
      });
    });
  
    const chartData = [
      { type: 'Completed', value: completedTasks },
      { type: 'Incomplete', value: incompleteTasks }
    ];
  
    return chartData;
  };
  

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        sort: ["date:desc"],
        pagination: {
          limit: MAX_PAGINATION_LIMIT,
        },
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        setData(
          response?.data?.data?.map((item) => ({
            id: item?.id,
            first_tasks: {
              data: {
                attributes: item?.attributes?.tasks?.[0],
              },
            },
            finished_date: moment(item?.attributes?.tasks?.[0]?.finished_date).format("DD-MM-YYYY"),
            ...item?.attributes,
          }))
        );

        const data = secondChart(response);
        
        setchartData(data);

      const groupedData = response?.data?.data?.reduce((acc, item) => {
        const employee = item?.attributes?.employee?.data?.attributes?.first_name;
        const tasks = item?.attributes?.tasks || [];
        if (!acc[employee]) {
          acc[employee] = { completed: 0, incomplete: 0 };
        }
        tasks.forEach(task => {
          if (task.completed === "Yes") {
            acc[employee].completed += 1;
          } else {
            acc[employee].incomplete += 1;
          }
        });
        return acc;
      }, {});

      const formattedChartData = Object.entries(groupedData || {}).map(([employee, counts]) => [
        { name: `${employee}`, type: `Completed`, value: counts.completed },
        { name: `${employee}`, type: `Incomplete`, value: counts.incomplete }
      ]).flat();

      setSecondChartData(formattedChartData);

      setTotal(response?.data?.meta?.pagination?.total);

      setPagination({
        ...response?.data?.meta?.pagination,
        pageSize: 10,
      });
    }
    } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

const refreshData = () => {
  onSearch();
};

const downloadReport = () => {
  try {
    const data = {
      code: config?.code,
      params: {
        start_date: searchValues?.start_date
          ? searchValues?.start_date?.format("YYYY-MM-DD")
          : moment().subtract(1000, "years").format("YYYY-MM-DD"),
        end_date: searchValues?.end_date
          ? searchValues?.end_date?.format("YYYY-MM-DD")
          : moment().add(1000, "years").format("YYYY-MM-DD"),
      },
    };

    const link = document.createElement("a");
    link.href = `${process.env.REACT_APP_BASE_API_URL
      }/reporter?${qs.stringify(data)}`;

    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.log(error);
  }
};

const getColumnFilterProps = ({ dataIndex, references = {} }) => {
  if (typeof references?.fieldName === "function") {
    const transformer = references?.fieldName;

    return {
      render: (data) => transformer(data?.data?.attributes) || "N/A",
    };
  } else {
    return {
      render: (data) =>
        data?.data?.attributes?.[references?.fieldName] || "N/A",
    };
  }
};

const getColumnProps = (column) => {
  if (column?.references) {
    return getColumnFilterProps(column);
  }

  return column;
};

const onSearch = () => {
  setSearched(true);

  fetchData({
    $and: [
      {
        $or: [
          {
            employee: {

              first_name: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },

            }
          },
          {
            createdAt: {
              $gte: moment(searchValues?.start_date).format(DATE_TIME_FORMAT),
              $lte: moment(searchValues?.end_date).format(DATE_TIME_FORMAT),
            },
          },
        ],
      },

      getFiltersForRoles(),
    ],
  });
};

const onReset = () => {
  setSearchValues({});
  fetchData(getFiltersForRoles());
  setSearched(false);
};

const getResultsMessage = () => {
  if (!_.isEmpty(searchValues) && !loading && searched) {
    return total > 0 ? (
      <span>
        <strong>{total}</strong> matched results
      </span>
    ) : (
      <span>No matched results</span>
    );
  }

  console.log('gsdfgsgv')

  return (
    <span>
      <strong>{total}</strong> total records
    </span>
  );
};

const renderHeaderButtons = () => {
  let buttons = [
    <Tooltip placement="top" title="Refresh">
      <Button
        icon={<ReloadOutlined />}
        onClick={refreshData}
        loading={loading}
      />
    </Tooltip>,
  ];

  return buttons;
};

const getFiltersForRoles = () => {
  const userType = user?.user_type?.toLowerCase()?.replace(/\s/i, "_");

  if (user?.is_admin) {
    return {};
  }

  if (userType === "employee") {
    return {
      $or: [
        {
          project_managers: {
            uid: user?.uid,
          },
        },
        {
          members: {
            uid: user?.uid,
          },
        },
      ],
    };
  }

  return {
    [pluralize(userType)]: {
      uid: user?.uid,
    },
  };
};

useEffect(() => {
  fetchData(getFiltersForRoles());

  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

// const barConfig = {
//   data: secondChartData,
//   xField: 'value',
//   yField: 'type',
//   seriesField: 'type',
//   colorField: 'type',
//   tooltip: {
//     fields: ['type', 'value'],
//     showTitle: false,
//   },
//   barWidthRatio: 0.4,
//   height: 400,
//   xAxis: {
//     label: {
//       formatter: (v) => `${v}%`,
//     },
//   },
//   label: {
//     position: 'middle',
//     layout: [
//       {
//         type: 'interval-adjust-position',
//       },
//       {
//         type: 'interval-hide-overlap',
//       },
//       {
//         type: 'adjust-color',
//       },
//     ],
//   },
//   interactions: [{ type: 'active-region' }],
// };

const barConfig = {
  data: secondChartData,
  isStack: true,
  xField: 'name',
  yField: 'value',
  seriesField: 'type',
  color: ['#FF6F61', '#40A9FF'], // Customize colors as needed
  legend: {
    position: 'top-left',
  },
  label: {
    position: 'middle',
    layout: [{ type: 'interval-adjust-position' }, { type: 'interval-hide-overlap' }, { type: 'adjust-color' }],
  },
};

const secondChartConfig = {
  appendPadding: 10,
  data: chartData,
  angleField: 'value',
  colorField: 'type',
  radius: 1,
  label: {
    type: 'inner',
    offset: '-30%',
    content: '{value}',
    style: {
      fontSize: 14,
      textAlign: 'center',
    },
  },
  interactions: [{ type: 'element-active' }],
};

console.log('dfnabsfasdk', chartData)

return (
  <Box>

    <ReportDataTable
      data={data}
      config={{
        ...config,
        columns: config?.columns?.map((column) => ({
          ...column,
          ...getColumnProps(column),
        })),
      }}
      loading={loading}
      pagination={pagination}
      actions={{
        onAdd: () => navigate(`/quick-projects/${config?.slugs?.plural}/add`),
        onRefresh: refreshData,
      }}
      Toolbar={
        <div className="toolbar">
          <Row gutter={16}>
            <Col span={10}>
              <Input
                value={searchValues?.keyword}
                placeholder="Search for keyword..."
                onChange={(e) =>
                  setSearchValues((prev) => ({
                    ...prev,
                    keyword: e.target.value,
                  }))
                }
              />
            </Col>

            <Col span={7}>
              <DatePicker
                onChange={(value) =>
                  setSearchValues((prev) => ({
                    ...prev,
                    start_date: value,
                  }))
                }
                value={searchValues?.start_date}
                placeholder="Select start date"
                style={styles.datePicker}
              />
            </Col>

            <Col span={7}>
              <DatePicker
                onChange={(value) =>
                  setSearchValues((prev) => ({
                    ...prev,
                    end_date: value,
                  }))
                }
                value={searchValues?.endDate}
                placeholder="Select end date"
                style={styles.datePicker}
              />
            </Col>
          </Row>

          <div className="toolbar-buttons">
            <p className="toolbar-results">{getResultsMessage()}</p>

            <Space>
              <Button onClick={onReset}>Reset</Button>
              <Button type="primary" onClick={onSearch}>
                Search
              </Button>
            </Space>
          </div>
        </div>
      }
      buttons={renderHeaderButtons()}
      onBack={() => navigate(`/quick-projects/reports`)}
    />


    <div style={{ marginBottom: '30px' }} id="barGraph">
      <Column {...barConfig} />
    </div>


    <div style={{ marginBottom: '30px' }} id="pieChart">
      <Pie {...secondChartConfig} />
    </div>

  </Box>
);
};

const styles = {
  breadcrumbs: {},
  pageHeader: {
    padding: 0,
    paddingBottom: 12,
  },
};

export default Page;