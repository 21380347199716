import React, { useRef, useState } from 'react'
import { axios } from '../../../../../App';
import config from './config';
import qs from "qs";
import { useLocation, useNavigate } from 'react-router-dom';
import { DataTable } from '../../../components';
import { Button, Input, Modal, Popconfirm, Select, Space, Tooltip, notification } from 'antd';
import { DeleteOutlined, EditOutlined, DownloadOutlined } from "@ant-design/icons";
import { ImFilePdf } from 'react-icons/im';
import _ from "lodash";
import { useEffect } from 'react';
import moment from "moment"
import { RiFileCopyLine } from "react-icons/ri";
import { v4 } from "uuid";
import { BASE_API_URL, STRAPI_URL } from '../../../../../constants';


const { Option } = Select;

const QuotationList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [letterheadFileName, setLetterheadFileName] = useState("");
  const [showsModal, setShowsModal] = useState(false);
  const [letterheads, setLetterheads] = useState([]);
  const [selectedData, setselectedData] = useState(null);
  const [companies, setCompanies] = useState([])

  const componentRef = useRef();

  const styles = {
    toolbar: {
      marginBottom: 24,
    },
    rightToolbar: {
      textAlign: "right",
    },
    pageHeader: {
      padding: 0,
      paddingBottom: 24,
    },
    label: {
      display: "block",
      marginBottom: 3,
      marginTop: 6,
    },
    previewedImage: {
      width: "100%",
    },
    select: {
      width: "100%",
    },
  };

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
      );

      const companyResponse = await axios.get(`${BASE_API_URL}/companies?populate=%2A&pagination%5Bpage%5D=1&pagination%5BpageSize%5D=9999`)

      if (response?.data) {
        setData(
          response?.data?.data?.map((item) => ({
            id: item?.id,
            name: `${item?.attributes?.po_from?.data?.attributes?.name} - ${item?.attributes?.client?.[0]?.client_name}`,
            ...item?.attributes,
          }))
        );

        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });
      }

      if (companyResponse?.data) {
        setCompanies(companyResponse?.data?.data)
      }

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onSearch = () => {
    fetchData();
  };

  const refreshData = () => {
    onSearch();
  };

  const deleteItem = async (id) => {
    try {
      const response = await axios.delete(`${config?.slugs?.plural}/${id}`);

      if (response?.data) {
        notification["success"]({
          message: `Remove ${config?.entityNames?.singular}`,
          description: `Remove ${config?.entityNames?.singular} successfully!`,
        });

        refreshData();
      } else {
        throw new Error();
      }
    } catch (error) {
      notification["error"]({
        message: `Remove ${config?.entityNames?.singular}`,
        description: `Remove ${config?.entityNames?.singular} failed!`,
      });
    }
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const fetchLetterheads = async () => {
    try {
      const resp = await axios.get(`/letterheads?populate=*`);

      if (resp?.data?.data?.length > 0) {
        const results = resp?.data?.data?.map((record, index) => {
          let fileName =
            record?.attributes?.image_file?.data?.attributes?.url?.split(
              "/"
            )[2] || "";

          if (index === 0) {
            setLetterheadFileName(fileName);
          }

          return {
            id: record?.id,
            name: record?.attributes?.name,
            file_name: fileName,
          };
        });

        setLetterheads(results);
      }
    } catch (error) { }
  };

  // const handlePrint = () => {
  //   const input = componentRef.current;
  //   html2canvas(input, { scale: 2 }).then((canvas) => {
  //     const imgData = canvas.toDataURL('image/jpg');
  //     const pdf = new jsPDF('p', 'mm', 'a4');
  //     const imgWidth = 210; // A4 width in mm
  //     const pageHeight = 295; // A4 height in mm
  //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     let heightLeft = imgHeight;
  //     let position = 0;

  //     pdf.addImage(imgData, 'JPG', 0, position, imgWidth, imgHeight);
  //     heightLeft -= pageHeight;

  //     while (heightLeft >= 0) {
  //       position = heightLeft - imgHeight;
  //       pdf.addPage();
  //       pdf.addImage(imgData, 'JPG', 0, position, imgWidth, imgHeight);
  //       heightLeft -= pageHeight;
  //     }

  //     pdf.save(`invoice_${selectedData?.po_number}.pdf`);
  //   });
  // };

  function handlePrint() {
    setTimeout(() => {
      var element = componentRef?.current?.innerHTML;

      var opt = {
        margin: [0.03, 0, 0.3, 0],
        filename: "quotation_" + selectedData?.po_number + ".pdf",
        image: { type: "jpeg", quality: 0.5 },
        pagebreak: { avoid: "tr", mode: "css", before: "#nextpage1" },
        html2canvas: {
          scale: 5,
          useCORS: true,
        },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
        compressImage: false,
      };

      import("html2pdf.js").then((html2pdf) => {
        /* html2pdf().set(opt).from(element).save(); */
        html2pdf
          .default()
          .set(opt)
          .from(element)
          .save()
      });
    }, 10);
  }

  const onCopy = async (inputData) => {

    const updatedClients = inputData?.client?.map(client => ({
      ...client,
      client_name: `Copy of ${client.client_name}`
    }));

    try {
      const newData = {
        ...inputData,
        bank: inputData?.bank?.data?.id,
        po_from: inputData?.po_from?.data?.id,
        client: updatedClients,
        uid: v4(),
        // creator: auth?.user?.id,
      };

      delete newData["id"];
      delete newData["creator"];
      delete newData["createdAt"];
      delete newData["updatedAt"];

      console.log('new Data', newData, inputData)

      const response = await axios.post(`/${config?.slugs?.plural}`, {
        data: newData,
      });

      if (response?.data) {
        notification["success"]({
          message: `Copy ${config?.entityNames?.singular}`,
          description: `Copy ${config?.entityNames?.singular} successfully!`,
        });

        refreshData();
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log("error", error);

      notification["error"]({
        message: `Copy ${config?.entityNames?.singular}`,
        description: `Copy ${config?.entityNames?.singular} failed!`,
      });
    }
  };


  useEffect(() => {
    fetchLetterheads();
  }, []);

  useEffect(() => {
    fetchData();
  }, [])

  console.log('selected Data', data, companies)

  return (
    <>
      <DataTable
        data={data}
        config={{
          ...config,
          columns: config?.columns
            ?.map((column) => ({
              ...column,
              ...getColumnProps(column),
            }))
            ?.concat({
              title: "Actions",
              width: 100,
              selectable: false,
              render: (render) => {
                return (
                  <Space>
                    <Tooltip
                      placement="bottom"
                      title={`Copy ${config?.entityNames?.singular}`}
                    >
                      <Button
                        style={{ paddingLeft: "7px" }}
                        icon={<RiFileCopyLine />}
                        onClick={() => onCopy(render)}
                      />
                    </Tooltip>
                    <Tooltip
                      placement="bottom"
                      title={`Edit ${config?.entityNames?.singular}`}
                    >
                      <Button
                        icon={<EditOutlined />}
                        onClick={() =>
                          navigate(
                            `/quick-stocks/${config?.slugs?.plural}/edit/${render?.id}`
                          )
                        }
                      />
                    </Tooltip>

                    <Tooltip placement="bottom" title="Download PDF">
                      <Button
                        icon={<ImFilePdf style={{ margin: "0 auto" }} />}
                        onClick={() => {
                          setShowsModal(true);
                          setselectedData(render)
                        }} />
                    </Tooltip>

                    <Popconfirm
                      title={`Are you sure to remove this ${config?.entityNames?.singular}?`}
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => deleteItem(render?.id)}
                    >
                      <Tooltip
                        placement="bottom"
                        title={`Remove ${config?.entityNames?.singular}`}
                      >
                        <Button type="danger" icon={<DeleteOutlined />} />
                      </Tooltip>
                    </Popconfirm>

                  </Space>
                );
              },
            }),
        }}
        loading={loading}
        pagination={pagination}
        actions={{
          onAdd: () => navigate(`/quick-stocks/${config?.slugs?.plural}/add`),
          // onRefresh: refreshData,
        }}
        Toolbar={
          <div className="toolbar">
            <Input
              value={searchValues?.keyword}
              placeholder="Search for keyword..."
              onChange={(e) =>
                setSearchValues((prev) => ({
                  ...prev,
                  keyword: e.target.value,
                }))
              }
            />

            <div className="toolbar-buttons">
              {/* <p className="toolbar-results">{getResultsMessage()}</p> */}

              <Space>
                {/* <Button onClick={onReset}>Reset</Button>
              <Button type="primary" onClick={onSearch}>
                Search
              </Button> */}
              </Space>
            </div>
          </div>
        }
      />

      <Modal
        title="Print Invoice"
        visible={showsModal}
        onCancel={() => setShowsModal(false)}
        destroyOnClose
        width={800} // Setting the width of the modal
        footer={null} // Removing the footer of the modal
      >

        <label style={{ ...styles.label, marginTop: 0 }}>Letterhead</label>
        <Select
          value={letterheadFileName}
          onChange={setLetterheadFileName}
          style={styles.select}
        >
          {letterheads?.map((letterhead, index) => (
            <Option key={index} value={letterhead?.file_name}>
              {letterhead?.name}
            </Option>
          ))}
        </Select>

        <label style={styles.label}>Preview</label>
        <img
          src={`${process.env.REACT_APP_BASE_API_URL}/uploads/${letterheadFileName}`}
          style={styles.previewedImage}
          alt="Preview"
        />

        {/* Hidden Invoice component */}
        {/* <div style={{ position: 'absolute', left: '-9999px', top: 0 }}>
          <Invoice ref={componentRef} data={selectedData} letterheadFileName={letterheadFileName} />
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <ReactToPrint
            trigger={() => <Button type="primary">Print</Button>}
            content={() => componentRef.current}
          />
        </div> */}

        {/* Hidden Invoice component */}
        <div ref={componentRef} style={{ position: 'absolute', left: '-9999px', top: 0 }}>
          {/* <div ref={componentRef} > */}
          <Invoice data={selectedData} letterheadFileName={letterheadFileName} companies={companies} />
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <Button type="primary" onClick={handlePrint}>Download PDF</Button>
        </div>
      </Modal>
    </>
  )
}

const Invoice = React.forwardRef(({ data, letterheadFileName, companies }, ref) => {

  const [companLogo, setCompanLogo] = useState(null)

  const totalSum = data && data?.item?.reduce((acc, item) => acc + item.total, 0);
  const taxableAmountSum = data && data?.item?.reduce((acc, item) => acc + item.taxable_amount, 0);
  const totalTaxAmount = data && data?.item?.reduce((acc, item) => acc + item.tax_amount, 0);

  function numberToWords(num) {
    const a = [
      '', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten',
      'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'
    ];
    const b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    const c = ['Hundred', 'Thousand', 'Lakh', 'Crore'];

    if ((num = num.toString()).length > 9) return 'overflow';
    let n = ('000000000' + num).substr(-9).match(/.{1,2}/g);
    if (!n) return '';

    let str = '';
    str += (n[0] !== '00') ? (a[Number(n[0])] || b[n[0][0]] + ' ' + a[n[0][1]]) + ' ' + c[3] + ' ' : '';
    str += (n[1] !== '00') ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + ' ' + c[2] + ' ' : '';
    str += (n[2] !== '00') ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + ' ' + c[1] + ' ' : '';
    str += (n[3] !== '00') ? ((a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + ' ' + c[0] + ' ') : '';
    str += (n[4] !== '00') ? ((a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]])) + ' ' : '';

    return str.trim();
  }

  function convertToCurrencyWords(num) {
    let [rupees, paise] = num.toFixed(2).split('.');

    let rupeesInWords = rupees > 0 ? numberToWords(rupees) + ' Rupees' : '';
    let paiseInWords = paise > 0 ? numberToWords(paise) + ' Paise' : '';

    console.log('rupeeee', rupeesInWords, '------', paiseInWords)

    return rupeesInWords + (paiseInWords ? ' and ' + paiseInWords : '') + ' Only';
  }

  useEffect(() => {
    const logo = companies?.find((comp) => comp?.id === data?.po_from?.data?.id)

    if (logo) {
      setCompanLogo(logo)
    }

  }, [companies, data])

  console.log('quotatuon', data)

  return (
    <div ref={ref} style={{ fontFamily: 'Arial, sans-serif', margin: '0 auto', border: '1px solid #ddd' }}>
      {/* {letterheadFileName && (
        (letterheadFileName.endsWith('.jpg') ||
          letterheadFileName.endsWith('.jpeg') ||
          letterheadFileName.endsWith('.png') ||
          letterheadFileName.endsWith('.gif') ||
          letterheadFileName.endsWith('.bmp') ||
          letterheadFileName.endsWith('.svg')) && (
          <div style={{ width: '100%', marginBottom: '20px' }}>
            <img
              src={`${process.env.REACT_APP_BASE_API_URL}/uploads/${letterheadFileName}`}
              alt="Letterhead"
              style={{ height: '100px', width: '100%', objectFit: 'cover' }}
              onLoad={() => console.log("Image loaded")}
            />
          </div>
        )
      )} */}

      <div style={{ padding: '0 40px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '12px' }}>

          <p>{moment(data?.createdAt).format('DD/mm/yy, hh:mm A')}</p>
          <p>Quotation | {data?.po_number}</p>

        </div>

        <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '20px' }}>
          {/* <div>
  <img src={`${STRAPI_URL}${companLogo?.attributes?.logo?.data?.attributes?.url}`} alt="Authorised Signatory" style={{ width: '120px' }} />
</div> */}
          <div>
            <h2 style={{ fontSize: '32px', fontWeight: '500' }}>Quotation</h2>
            <h3 style={{ fontSize: '32px', fontWeight: '500' }}>{data?.po_number}</h3>
          </div>
        </div>

        <table style={{ width: '100%', marginBottom: '20px' }}>
          <tbody>
            <tr>
              {data?.client?.length > 0 && (

                <td style={{ width: '33%', verticalAlign: 'top', padding: '10px', border: '1px solid #ddd' }}>
                  <strong>Name and Address of Buyer</strong><br />
                  {data?.client[0]?.client_name}<br />
                  {data?.client[0]?.po_address}<br />
                  <strong>GSTIN:</strong> {data?.client[0]?.gstin_number}<br />
                  <strong>Place of Supply:</strong> {data?.client[0]?.place_of_supply}
                </td>
              )}
              <td style={{ width: '33%', verticalAlign: 'top', padding: '10px', border: '1px solid #ddd' }}>
                <strong>Name and Address of Supplier</strong><br />
                {data?.po_from?.data?.attributes?.name}<br />
                {data?.po_from?.data?.attributes?.address_line_1},<br />
                {data?.po_from?.data?.attributes?.address_line_2}<br />
                <strong>GSTIN:</strong> {data?.po_from?.data?.attributes?.gsin_no}
              </td>
              <td style={{ width: '33%', verticalAlign: 'top', padding: '10px', border: '1px solid #ddd' }}>
                <strong>Shipping Details</strong><br />
                {data?.shipped_to?.[0]?.name}<br />
                {data.shipped_to?.[0]?.address}<br />
                <strong>GSTIN:</strong> {data?.shipped_to?.[0]?.gstin_number}
              </td>
            </tr>
          </tbody>
        </table>

        <div>
          <p style={{ textAlign: 'center' }}>Quotation Details</p>

          <div
            style={{
              margin: '10px 0',
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)', // Adjust this to 'repeat(4, 1fr)' if you want 4 columns.
              gap: '10px', // Adjust the gap between grid items
              fontSize: '14px',
            }}>
            <p> <strong>Quotation Number:</strong>  {data?.po_number}</p>
            <p><strong>Delivery Date:</strong> {moment(data?.client[0]?.po_date).format('DD/MM/YYYY')}</p>
            <p><strong>No of Items:</strong>  {data?.item?.length}</p>
            <p><strong>Quotation Amount:</strong> {totalSum}</p>
          </div>
        </div>


        <table style={{ width: '100%', marginBottom: '20px', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid #ddd', padding: '10px' }}>#</th>
              <th style={{ border: '1px solid #ddd', padding: '10px' }}>Material Item</th>
              {/* <th style={{ border: '1px solid #ddd', padding: '10px' }}>Description</th> */}
              <th style={{ border: '1px solid #ddd', padding: '10px' }}>HSN/SAC Code</th>
              <th style={{ border: '1px solid #ddd', padding: '10px' }}>Quantity</th>
              <th style={{ border: '1px solid #ddd', padding: '10px' }}>Rate</th>
              <th style={{ border: '1px solid #ddd', padding: '10px' }}>Taxable Amount</th>
              <th style={{ border: '1px solid #ddd', padding: '10px' }}>Tax Rate</th>
              <th style={{ border: '1px solid #ddd', padding: '10px' }}>Tax Amount</th>
              <th style={{ border: '1px solid #ddd', padding: '10px' }}>Total</th>
            </tr>
          </thead>
          {data?.item?.map((item, i) => (

            <tbody>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '10px' }}>{i + 1}</td>
                <td style={{ border: '1px solid #ddd', padding: '10px' }}>{item?.material_item}</td>
                {/* <td style={{ border: '1px solid #ddd', padding: '10px' }}>
                  <p dangerouslySetInnerHTML={{ __html: item?.description }} />
                </td> */}
                <td style={{ border: '1px solid #ddd', padding: '10px' }}>{item?.hsn_code}</td>
                <td style={{ border: '1px solid #ddd', padding: '10px' }}>{item?.quantity}</td>
                <td style={{ border: '1px solid #ddd', padding: '10px' }}>₹{item?.rate}</td>
                <td style={{ border: '1px solid #ddd', padding: '10px' }}>₹{item?.taxable_amount}</td>
                <td style={{ border: '1px solid #ddd', padding: '10px' }}>{item?.gst}%</td>
                <td style={{ border: '1px solid #ddd', padding: '10px' }}>₹{item?.tax_amount}</td>
                <td style={{ border: '1px solid #ddd', padding: '10px' }}>₹{item?.total}</td>
              </tr>
            </tbody>
          ))}
        </table>

        <div style={{ display: 'flex', gap: '40px', justifyContent: 'space-between' }}>
          <div style={{ marginBottom: '20px' }}>
            <strong>Quotation Amount:</strong> {convertToCurrencyWords(totalSum)}<br />
            <strong>Tax:</strong> {convertToCurrencyWords(totalTaxAmount)}<br />
          </div>

          <div>
            <strong>Total (before Tax):</strong> {taxableAmountSum}<br />

            <table style={{ width: '100%', margin: '10px 0', border: '1px solid #ddd', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th style={{ border: '1px solid #ddd', padding: '10px' }}>Total Tax</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ border: '1px solid #ddd', padding: '10px', textAlign: 'center' }}>₹{totalSum - taxableAmountSum}</td>
                </tr>
              </tbody>
            </table>

            <div style={{ margin: '20px 0' }}>
              <strong>Total Tax:</strong> ₹{totalTaxAmount}<br />
              <strong>Total (after Tax):</strong> ₹{totalSum}<br />
              <strong>Grand Total:</strong> ₹{totalSum}
            </div>
          </div>

        </div>

        <div style={{ marginBottom: '20px', pageBreakInside: 'avoid' }}>
          <strong>Terms And Conditions:</strong><br />
          {data?.term?.length > 0 ? (
            <table style={{ width: '100%', margin: '10px 0', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th style={{ border: '1px solid #ddd', padding: '10px' }}>#</th>
                  <th style={{ border: '1px solid #ddd', padding: '10px' }}>Heading</th>
                  <th style={{ border: '1px solid #ddd', padding: '10px' }}>Description</th>
                  <th style={{ border: '1px solid #ddd', padding: '10px' }}>Material Group</th>
                  <th style={{ border: '1px solid #ddd', padding: '10px' }}>Material Item</th>
                </tr>
              </thead>
              {data?.term?.map((item, i) => (

                <tbody>
                  <tr>
                    <td style={{ textAlign: 'center', border: '1px solid #ddd', padding: '10px' }}>{i + 1}</td>
                    <td style={{ textAlign: 'center', border: '1px solid #ddd', padding: '10px' }}>{item?.heading}</td>
                    <td style={{ textAlign: 'center', border: '1px solid #ddd', padding: '10px' }}>
                      <p dangerouslySetInnerHTML={{ __html: item?.description }} />
                    </td>
                    <td style={{ textAlign: 'center', border: '1px solid #ddd', padding: '10px' }}>{item?.material_group}</td>
                    <td style={{ textAlign: 'center', border: '1px solid #ddd', padding: '10px' }}>{item?.material_item}</td>
                  </tr>
                </tbody>
              ))}
            </table>
          ) : (
            <p>
              This is a computer generated document.
            </p>
          )}
        </div>

        <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column', borderTop: '1px solid #ddd', padding: '10px 0 50px' }}>
          <p style={{ textAlign: 'center', fontSize: '16px', fontWeight: '600' }}>For {data?.po_from?.data?.attributes?.name}</p>

          <p style={{ fontSize: '12px' }}>Authorised Signatory</p>
        </div>
      </div>
    </div>
  );
});

export default QuotationList